import headerLogo from '../../assets/Falcon-Logo-File.png';

export const MenuData = [{
    text: "Home", href: "#"
}, {
    text: "About", href: "#"
}, {
    text: "Features", href: "#"
}, {
    text: "PortFolio", href: "#"
}, {
    text: "Testimonial", href: "#"
}, {
    text: "Clients", href: "#"
}, {
    text: "Pricing", href: "#"
}, {
    text: "Blog", href: "#"
}, {
    text: "Contact", href: "#"
},]

export const HeaderMainlogo = { headerMainLogo: headerLogo };