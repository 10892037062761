import PortfolioImage from "../../assets/simple.png"

export const ProtfolioCardData = [
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
    { cardImage: [PortfolioImage], subHeading: "Gallery", counter: "200", heading: "Simple Safety Construction Website" },
]

export const PortfolioData = { subHeading: "Visit our portfolio and keep your feedback", heading: "Our portfolio" }