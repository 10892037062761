import PrimaryButton from '../../common/form/button/primaryButton';
import styles from './header.module.css';
import { MenuData } from '../../constants/header/menuData.ts';
import { HeaderMainlogo } from '../../constants/header/menuData.ts';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';

const Header = () => {
  return (
    <div className="px-5 border-bottom">
      <nav
        className={`${styles.navbar} d-flex align-items-center justify-content-between py-2`}
      >
        <div>
          <img
            src={HeaderMainlogo.headerMainLogo}
            style={{ width: '80px', height: 'auto' }}
          />
        </div>
        <div className={styles.navIcon}>
          <HiOutlineMenuAlt3 />
        </div>
        <div className={`${styles.webMenu} align-items-center`}>
          <ul className="mb-0">
            {MenuData.map((item, index) => (
              <li key={index}>
                <a href={item.href}>{item.text}</a>
              </li>
            ))}
          </ul>
          <div className="ms-3">
            <PrimaryButton text="Login" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
